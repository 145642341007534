$font-stack: roboto, arial, helvetica, sans-serif;

$floating-element-shadow: 0 20px 40px -20px rgb(0 0 0 / 50%);
$bounce-in-motion: cubic-bezier(0.22, -0.57, 0.51, 1.07);

$z-index-spinner: 9999;
$z-index-modal: 400;
$z-index-notification-area: 320;
$z-index-app-bar: 310;
$z-index-drawer: 300;
$z-index-floating-action-buttons: 120;
$z-index-controls: 90;
$z-index-axis-viewer: 80;
$z-index-scene: 1;
$z-index-input: 10;
$z-index-input-popout: 15;
$z-index-input-hint: 5;

$app-bar-height: 7.5rem;
$app-nav-icon-width: 7.5rem;

$content-layer-spacing: 2rem;

// Media Queries
$phone-min-size: 0;
$phone-max-size: 72rem;

$tablet-min-size: 72rem;
$tablet-max-size: 96rem;

$desktop-min-size: 96rem;
$desktop-max-size: 2000rem;

$user-menu-height: 10rem;
