@import '../global/colors';

.bx__grid {
    background: $white;
    width: 100%;
    border: 1px solid $light-blue-gray;
    border-top: none;
    table-layout: fixed;

    &.clickable {
        .cdk-row {
            cursor: pointer;
        }
    }

    &.with-forms {
        .cdk-cell,
        td {
            padding: 0;
        }

        .text-column {
            padding-left: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .cdk-header-row,
    .cdk-row,
    tr {
        height: 5rem;
        border-bottom: 1px solid $light-blue-gray;
        word-break: break-word;

        .cdk-header-cell,
        .cdk-cell,
        th,
        td {
            padding: 0;

            &.center {
                text-align: center;
            }

            &.cdk-table-sticky {
                background: white;

                // https://stackoverflow.com/questions/41882616/why-border-is-not-visible-with-position-sticky-when-background-color-exists
                &:after {
                    content: '';
                    position: absolute;
                    inset: -1px 0;
                    border-bottom: 1px solid $light-blue-gray;
                    border-top: 1px solid $light-blue-gray;
                }
            }
        }

        .cdk-header-cell,
        th {
            height: 6rem;
        }

        .cdk-cell,
        .cdk-header-cell,
        th,
        td {
            padding: 0 1.5rem;
        }

        /* Named cells */
        .cdk-column-checkbox {
            width: 8rem;

            input {
                margin: 0 auto;
            }
        }
    }

    .cdk-header-row,
    thead tr {
        font-weight: bold;
        background: $white;
        border-top: 1px solid $light-blue-gray;
    }

    $boxShadowTop: 0 -7px 9px -7px rgb(0 0 0 / 15%);
    $boxShadowBottom: 0 7px 9px -6px rgb(0 0 0 / 15%);

    .cdk-row:hover,
    tr {
        transition: box-shadow 0.2s ease;

        &:hover {
            box-shadow: $boxShadowBottom, $boxShadowTop;
        }
    }

    tbody tr:hover:last-of-type {
        box-shadow: $boxShadowTop;
    }

    .cdk-column-name {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (width <= 1023px) {
            width: 80px;
        }
    }
}
