@import 'global/reset';
@import 'global/colors';

* {
    box-sizing: border-box;
}

html {
    overflow-y: auto !important;
}

body {
    background: $gray-3;
}

a {
    color: $brand-green;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: 0.3s color, 0.3s border-bottom-color;
}

@import 'global/blur-container';
@import 'global/buttons';
@import 'global/content-structuring';
@import 'global/helpers';
@import 'global/lists';
@import 'global/typo';
@import 'components/card';
@import 'components/grid';
@import 'components/input';
