@import 'colors';
@import 'mixins';
@import 'variables';

$button-primary-hover-bg-color: $brand-green;

button {
    background: none;
    border: none;
    font-size: 1.625rem;
    color: $dark-gray;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: relative;
    outline: none;

    &:focus {
        outline: none;
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.primary,
    &.secondary {
        //ripple effect
        &:focus:not(:active) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-image: radial-gradient(circle at 50% 50%, $black 0%, black 50%, transparent 51%);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                opacity: 0;
                animation: ripple 0.5s;
            }
        }
    }

    &:not(.fab) {
        &.primary,
        &.secondary {
            height: 4.5rem;
            font-weight: 500;
            line-height: 1.2rem;
            text-transform: uppercase;
            border: none;
            border-radius: 3px;
            box-shadow: none;
            padding: 0 2.5rem;
            transition: 0.15s background-color ease-in-out;

            &:hover:not([disabled]) {
                box-shadow: none;
            }
        }

        &:not(.basic) {
            &.primary,
            &.secondary {
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);

                &:hover:not([disabled]),
                &:focus:not([disabled]) {
                    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
                }

                &:active:not([disabled]) {
                    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 24%), 0 8px 10px 1px rgb(0 0 0 / 18%), 0 3px 14px 2px rgb(0 0 0 / 16%);
                }
            }

            &.primary {
                background-color: $brand-green;
                color: $white;

                &:hover:not([disabled]) {
                    background-color: $button-primary-hover-bg-color;
                }

                &:focus:not([disabled]) {
                    background-color: $light-green;
                }
            }

            &.secondary {
                background-color: $carbon-gray;
                color: $white;

                &:hover:not([disabled]) {
                    background-color: $gray-12;
                }

                &:focus:not([disabled]) {
                    background-color: $gray-9;
                }
            }
        }

        &.basic {
            &.primary {
                background-color: transparent;
                color: $brand-green;

                &:hover:not([disabled]) {
                    background-color: rgba($color: $brand-green, $alpha: 10%);
                }
            }

            &.secondary {
                background-color: transparent;
                color: $carbon-gray;

                &:hover:not([disabled]) {
                    background-color: rgba($color: $carbon-gray, $alpha: 10%);
                }
            }
        }

        &.icon {
            padding: 0 1rem;
        }

        &.simple {
            &.creative {
                color: $light-green;
            }

            &.destructive {
                color: $red;
            }
        }
    }
}

.fab {
    border-radius: 50%;
    height: 6.25rem;
    width: 6.25rem;
    border: 1px solid transparent;
    display: flex;
    z-index: $z-index-floating-action-buttons;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 3px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
    transition: box-shadow 0.25s ease;

    @include transition(0.15s, background-color);

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 12px 1px rgb(0 0 0 / 14%), 0 3px 16px 2px rgb(0 0 0 / 12%);
    }

    &:active:not([disabled]) {
        box-shadow: 0 5px 5px -3px rgb(0 0 0 / 24%), 0 8px 10px 1px rgb(0 0 0 / 18%), 0 3px 14px 2px rgb(0 0 0 / 16%);
    }

    &:focus {
        outline: none;
    }

    &[disabled] {
        opacity: 0.4;
    }

    &.primary {
        background-color: $brand-green;
        color: $white;

        &:hover {
            background-color: $button-primary-hover-bg-color;
        }
    }

    &.secondary {
        background: $white;
        color: $dark-gray;

        &:hover {
            background-color: $gray-3;
        }
    }

    &.destructive {
        background-color: $red;
        color: $white;

        &:hover {
            background-color: lighten($red, 7.5%);
        }
    }

    &.drawer-button {
        left: -2%;
    }

    bx-icon {
        display: inline-flex;
        margin: auto;

        @include transition();
    }

    &[disabled] {
        bx-icon {
            opacity: 0.4;
        }
    }
}

.button-bar {
    background: $white;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    padding: 1rem;

    button {
        & + button {
            margin-left: 1rem;
        }
    }

    .left {
        margin-right: auto;
    }

    .right {
        margin-left: auto;
    }
}

@keyframes ripple {
    0% {
        opacity: 0;
        background-size: 0;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 0;
        background-size: 200%;
    }
}
