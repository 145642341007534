// Swatches
$dark-gray: #404547;
$blue-gray: #A3B0BF;
$light-blue-gray: #E0E6F4;
$gray: #D4DDE1;
$light-gray: #F0F4F6;
$lighter-gray: #F4F8FA;
$light-green: #28C76F;
$green: #119E50;
$red: #FF584F;
$blue: #5399EA;

// Branding guidelines

$brand-green: #00893d;
$carbon-gray: #646464;
$black: #000;
$green: #0ebc5b;
$nordic-blue: #1d9bb2;
$red: #e62c27;
$yellow: #fccf46;
$white: #fff;
$gray-1: #f6f7f8;
$gray-2: #ebeef0;
$gray-3: #dde3e6;
$gray-4: #ced5da;
$gray-5: #b6bec1;
$gray-6: #aab1b4;
$gray-7: #9ca2a5;
$gray-8: #878d8f;
$gray-9: #787d7f;
$gray-10: #656a6c;
$gray-11: #55595a;
$gray-12: #414546;
$gray-13: #2d3031;

$brand-header-bar-gradient: linear-gradient(-192deg, $green 25%, $brand-green);
