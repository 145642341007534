@import '../global/colors';
@import '../global/mixins';

$input-height: 3rem;
$input-bg: $white;

$unit-width: 6rem;
$icon-width: 4rem;

bx-form-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    label {
        font-size: 1.5rem;
        font-weight: 500;
        color: $carbon-gray;
    }

    &.focus {
        label {
            color: $brand-green;
        }
    }

    .hint {
        font-size: 1.5rem;
        margin: 1.5rem 1rem 0;
        opacity: 0;
        font-weight: 500;

        @include transition(0.15s, opacity);

        &.visible {
            opacity: 1;
        }

        &.creative {
            color: $light-green;
        }

        &.destructive {
            color: $red;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.full {
        flex: 0 0 100%;
    }
}

bx-input-container {
    display: block;
    position: relative;

    bx-input-addons {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 1.5rem;
        bottom: 0;
        z-index: 3;

        * {
            margin-right: 0.5rem;

            &:last-child {
                margin: 0;
            }
        }

        bx-unit {
            color: $brand-green;
        }
    }

    &.display {
        input[bxInput] {
            padding-left: 1rem;
            border-color: transparent;
            border-bottom: none;

            &[disabled] {
                color: $gray-5;
            }

            &.right {
                text-align: right;
            }
        }

        // hide the combobox icon in display state
        bx-icon {
            opacity: 0;
        }
    }

    &.fill:not(.display) {
        input[bxInput] {
            background: $input-bg;
            height: 5rem;
            padding-left: 1rem;
            margin-top: 0;
            margin-bottom: 0;
            border: 1px solid $gray;
            border-bottom: 1px solid $gray;
            outline: none;

            &[disabled] {
                border-bottom: none;
            }

            &:focus {
                border-color: $green;

                @include transition(0.15s, border-color);

                box-shadow: 0 2px 10px 0 rgb(0 0 0 / 12%);
            }
        }
    }

    input[bxInput][disabled] {
        cursor: not-allowed;

        & + bx-input-addons {
            bx-unit {
                color: $gray-5;
            }

            bx-icon {
                color: $gray-5;
            }
        }
    }
}

input[bxInput] {
    height: $input-height;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.error {
    input[bxInput] {
        border-bottom: 2px solid $red !important;
    }
}

input[bxInput],
textarea[bxInput] {
    background-color: $input-bg;
    font-size: 1.625rem;
    font-weight: 500;
    color: $carbon-gray;
    width: 100%;
    border: none;
    border-bottom: 2px solid $gray-7;

    &.rounded {
        border-radius: 4px;
    }

    &.not-rounded {
        border-radius: 0;
    }

    &.rounded-left {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    &.rounded-right {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    &[disabled] {
        color: $gray-5;
        border-bottom: 2px dotted $gray-5;
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border-bottom: 2px solid $brand-green;
    }

    &.no-background {
        background: transparent;
    }

    &.no-border {
        border-color: transparent;
    }

    &[type='number'] {
        text-align: right;
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        &::placeholder {
            text-align: left;
        }
    }
}
